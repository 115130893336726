<template>
  <div class="section-sport">
    <div class="section-sport__header">
      <p class="title">Trực tiếp thể thao</p>
      <ViewMore :link="PAGE.SPORTS_SCHEDULE" hide-icon />
    </div>

    <div class="group-content">
      <div class="group-content__matches">
        <div v-if="futureMatches?.length">
          <Swiper v-bind="swiperOption" ref="mySwiperHotMatch">
            <SwiperSlide v-for="(match, index) in futureMatches" :key="index" class="swiper-slide">
              <HotMatchItem :item="match" :data-match_id="match.match_id" />
            </SwiperSlide>
          </Swiper>
        </div>
        <div v-else class="hot-match__content--none">
          <BaseImg src="/assets/images/components/desktop/pages/home/sports/no-data.png" alt="no data" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay } from 'swiper'
import HotMatchItem from './hot-match-item.vue'
import { useAppStore } from '~/store/app'
import { useHotMatch } from '~/composables/useHotMatch'
import ViewMore from '@/components/common/view-more'
import { PAGE } from '~/constants/router'

const { fetchHotMatch } = useHotMatch()
const { isBotChromeLighthouse } = useAppStore()

const futureMatches = ref([])
const isLoading = ref(false)

const swiperOption = reactive({
  slidesPerView: 'auto',
  lazy: true,
  autoplay: {
    delay: 100000,
    disableOnInteraction: false
  },
  spaceBetween: 10,
  modules: [Autoplay]
})

const fetchFutureMatch = async () => {
  isLoading.value = true
  const data = await fetchHotMatch(6)
  futureMatches.value = data || []
  const chunkSize = 1
  futureMatches.value = data?.reduce((acc, val, index) => {
    const chunkIndex = Math.floor(index / chunkSize)
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = []
    }
    acc[chunkIndex].push(val)
    return acc
  }, [])
  isLoading.value = false
  // Cheat: Improve google speed
  if (isBotChromeLighthouse) {
    futureMatches.value = [...futureMatches.value].slice(0, 3)
  }
}

useAsyncData(async () => {
  await fetchFutureMatch()
})
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/sport/index.scss"></style>
