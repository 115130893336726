import dayjs from 'dayjs'
import { useLobbyGameApi } from '@/api/lobby-game'
import { useAppStore } from '@/store/app'
import { useLoading } from '@/composables/useLoading'
const MIEN_BAC_ID = 1

const LOTTERY_TYPE = {
  XOSO: 'xs',
  XS_3_SO: '3',
  XS_2_SO: '2'
}

const listLotteryType = [
  { label: 'Xổ Số', lotteryType: LOTTERY_TYPE.XOSO },
  { label: '3 Số', lotteryType: LOTTERY_TYPE.XS_3_SO },
  { label: '2 Số', lotteryType: LOTTERY_TYPE.XS_2_SO }
]

const prizeList = [
  {
    key: 'prize1',
    text: 'Nhất'
  },
  {
    key: 'prize2',
    text: 'Nhì'
  },
  {
    key: 'prize3',
    text: 'Ba'
  },
  {
    key: 'prize4',
    text: 'Tư'
  },
  {
    key: 'prize5',
    text: 'Năm'
  },
  {
    key: 'prize6',
    text: 'Sáu'
  },
  {
    key: 'prize7',
    text: 'Bảy'
  },
  {
    key: 'prize8',
    text: 'Tám'
  }
]

export default () => {
  const { $config, $pinia } = useNuxtApp()
  const store = useAppStore($pinia)

  const { setOpenDatePicker } = store

  const { LODE_CITY, LODE_RESULT, LO_DE_GAMES } = useLobbyGameApi()
  const listCity = ref([{ id: 1, name: 'Miền Bắc' }])
  const listLottery = ref([])
  const lotteryResult = ref({})
  const selecteTypeRef = ref()
  const selecteProvinceRef = ref()
  const openDatePicker = ref(false)

  const dau = ref({})
  const duoi = ref({})
  const date = ref(dayjs().subtract(1, 'days'))
  const cityId = ref(MIEN_BAC_ID)
  const lotteryType = ref(LOTTERY_TYPE.XOSO)
  const sieuTocCountDown = ref(dayjs().add(1, 'minute').set('second', 0))
  const { isLoading, loading, load } = useLoading()

  const fetchCity = async (date) => {
    loading()
    try {
      date = dayjs(date).format('DD-MM-YYYY')
      const res = await $axios.get(`${LODE_CITY}?date=${date}`)
      if (res?.status === 200) {
        load()
        listCity.value = res.data?.data?.rows
      }
    } catch (error) {
      load()
      console.log('fetchCity', error)
    }
  }

  const fetchLotteryResult = async (date, city) => {
    loading()
    try {
      date = dayjs(date).format('DD-MM-YYYY')
      const res = await $axios.get(`${LODE_RESULT}?date=${date}&city=${city}`)
      if (res?.status === 200) {
        load()
        lotteryResult.value = res.data?.data?.rows
      }
    } catch (error) {
      load()
      console.log('fetchLotteryResult', error)
    }
  }

  const fetchListLottery = async (date) => {
    loading()
    try {
      const res = await $axios.get(`${LO_DE_GAMES}`)
      if (res?.status === 200) {
        load()
        listLottery.value = res.data?.data?.items
      }
    } catch (error) {
      load()
      console.log('fetchListLottery', error)
    }
  }

  useAsyncData(async () => {
    if (store.isLogged) {
      await fetchListLottery()
    }
    await fetchCity(date.value)
    await fetchLotteryResult(date.value, cityId.value)
  })

  watch(date, () => {
    cityId.value = 1
    fetchCity(date.value)
    fetchLotteryResult(date.value, cityId.value)
  })

  watch(cityId, () => {
    fetchLotteryResult(date.value, cityId.value)
  })

  watch(lotteryResult, () => {
    const listNumber = lotteryResult.value.result?.twooflast?.split('-').map((item) => item.trim())

    const temp1 = {}
    const temp2 = {}

    for (let i = 0; i < listNumber?.length; i++) {
      const numberItem = listNumber[i].split('')

      if (temp1[numberItem[0]]) {
        temp1[numberItem[0]].push(numberItem.join(''))
      } else {
        temp1[numberItem[0]] = [numberItem.join('')]
      }

      if (temp2[numberItem[1]]) {
        temp2[numberItem[1]].push(numberItem.join(''))
      } else {
        temp2[numberItem[1]] = [numberItem.join('')]
      }
    }

    dau.value = temp1
    duoi.value = temp2
  })

  const title = computed({
    get() {
      if (Array.isArray(lotteryResult.value.result)) {
        return lotteryResult.value.title?.replace(/-/g, '/')
      }
      const city = listCity.value.find((item) => item.id === cityId.value)
      return `Xổ Số ${city?.name} ${lotteryResult.value.title || ''}`
    }
  })

  const titleMobile = computed({
    get() {
      if (Array.isArray(lotteryResult.value.result)) {
        return lotteryResult.value.title?.replace(/-/g, '/')
      }

      const city = listCity?.value?.find((item) => item?.id === cityId?.value)
      return `<p>Xổ Số ${city?.name}</p><p>${lotteryResult.value.title || ''}</p>`
    }
  })

  const lotoTitle = computed({
    get() {
      if (Array.isArray(lotteryResult.value.result)) {
        return lotteryResult.value.title?.replace(/-/g, '/')
      }

      const city = listCity.value.find((item) => item.id === cityId.value)
      return `Lô tô ${city?.name} ${lotteryResult.value.title || ''}`
    }
  })
  const lotoTitleMobile = computed({
    get() {
      if (Array.isArray(lotteryResult.value.result)) {
        return lotteryResult.value.title?.replace(/-/g, '/')
      }

      const city = listCity.value.find((item) => item.id === cityId.value)
      return `<p>Lô tô ${city?.name}</p> <p>${lotteryResult.value.title || ''}</p>`
    }
  })

  const handleSelectDateOpen = () => {
    setOpenDatePicker(true)
    selecteProvinceRef.value?.hideOption?.()
    selecteTypeRef.value?.hideOption?.()
  }
  const handleSelectDateOpenMobile = () => {
    setOpenDatePicker(true)
    selecteProvinceRef.value?.hideOption?.()
  }

  return {
    fetchCity,
    fetchLotteryResult,
    handleSelectDateOpen,
    handleSelectDateOpenMobile,
    selecteTypeRef,
    selecteProvinceRef,
    lotteryResult,
    listCity,
    lotteryType,
    listLotteryType,
    title,
    lotoTitle,
    MIEN_BAC_ID,
    dau,
    duoi,
    date,
    cityId,
    sieuTocCountDown,
    LOTTERY_TYPE,
    prizeList,
    listLottery,
    openDatePicker,
    isLoading,
    titleMobile,
    lotoTitleMobile
  }
}
