import { LOBBY_GAME_URLS } from '~/config/page-url'

export const gameCenters = [
  {
    label: 'table-game',
    url: LOBBY_GAME_URLS.TABLE_GAME,
    text: 'Table Games',
    icon: '/assets/images/components/desktop/pages/home/games/icon-table-game.png',
    jackpot: 'jackpotIngame'
  },
  {
    label: 'quay-so',
    url: LOBBY_GAME_URLS.QUAY_SO,
    text: 'Quay Số',
    icon: '/assets/images/components/desktop/pages/home/games/icon-quay-so.png'
  },
  {
    label: 'lo-de',
    url: LOBBY_GAME_URLS.LO_DE,
    text: 'Lô Đề',
    icon: '/assets/images/components/desktop/pages/home/games/icon-lo-de.png'
  },
  {
    label: 'no-hu',
    url: LOBBY_GAME_URLS.NO_HU,
    text: 'Nổ Hũ',
    jackpot: 'jackpotNohu',
    icon: '/assets/images/components/desktop/pages/home/games/icon-no-hu.png'
  },
  {
    label: 'game-bai',
    url: LOBBY_GAME_URLS.GAME_BAI,
    text: 'Game Bài',
    icon: '/assets/images/components/desktop/pages/home/games/icon-game-bai.png'
  },
  {
    label: 'ban-ca',
    url: LOBBY_GAME_URLS.BAN_CA,
    text: 'Bắn Cá',
    jackpot: 'jackpotFishing',
    icon: '/assets/images/components/desktop/pages/home/games/icon-ban-ca.png'
  },
  {
    label: 'slots',
    url: LOBBY_GAME_URLS.SLOTS_GAME,
    text: 'Slots',
    icon: '/assets/images/components/desktop/pages/home/games/icon-slots.png'
  },
  {
    label: 'game-nhanh',
    url: LOBBY_GAME_URLS.QUICK_GAMES,
    text: 'Game Nhanh',
    icon: '/assets/images/components/desktop/pages/home/games/icon-game-nhanh.png'
  }
]
