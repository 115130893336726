<template>
  <div class="brand-info">
    <div class="brand-info__slogan">
      <h2>
        {{ FOOTER_CONTENT.SLOGAN }}
      </h2>
    </div>
    <p class="brand-info__slogan--description">
      {{ FOOTER_CONTENT.DESCRIPTION }}
    </p>
  </div>
</template>

<script setup>
import { footerContentData } from '@/constants/footer'
const FOOTER_CONTENT = footerContentData()
</script>

  <style scoped lang="scss" src="assets/scss/components/common/brand-info.scss" />
