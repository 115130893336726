<template>
  <div class="lottery">
    <SectionTitle title="Xổ số 24H" icon="/assets/images/components/desktop/icon-lottery.png" background />
    <div class="lottery-list">
      <div v-for="(item, index) in LOTTERY" :key="index" class="lottery-list__item">
        <div class="game-item__overlay">
          <button class="game-item__overlay--btn" @click.prevent="handleClick(item)">CƯỢC NGAY</button>
        </div>
        <base-img class="image" :src="item?.image" :alt="item.title" lazy />
      </div>
    </div>
    <div class="lottery__content">
      <div class="lottery__filter">
        <BaseSelect
          ref="selecteTypeRef"
          v-model="lotteryType"
          :items="listLotteryType"
          class="type-result-selection"
          value-field="lotteryType"
          text-field="label"
          is-show-icon-selected="true"
        />
        <BaseSelect
          ref="selecteProvinceRef"
          v-model="cityId"
          :items="listCity"
          class="province-selection"
          value-field="id"
          text-field="name"
        />
        <BaseDatepicker
          v-model="date"
          :on-menu-open="handleSelectDateOpen"
          :display-format="DATE_FORMATS.LODE_DATE_PICKER_FORMAT"
          :open-date-picker="openDatePicker"
          class="select-date-lottery"
          :max-date="maxDate"
        />
      </div>
      <LotteryResult
        v-if="[LOTTERY_TYPE.XOSO, LOTTERY_TYPE.XS_3_SO].includes(lotteryType)"
        :lottery-result="lotteryResult.result"
        :title="title"
        :city="cityId"
        :is-sort-number="lotteryType === LOTTERY_TYPE.XS_3_SO"
        :prize-list="prizeList"
      />
      <LotteryTwoNumberResult
        v-if="lotteryType === LOTTERY_TYPE.XS_2_SO"
        :dau="dau"
        :duoi="duoi"
        :title="lotoTitle"
        :lottery-result="lotteryResult.result"
      />
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import LotteryResult from './lottery-result.vue'
import LotteryTwoNumberResult from './lottery-two-number-result.vue'
import SectionTitle from '@/components/common/section-title'
import useLottery from '@/composables/useLottery'
import BaseSelect from '@/components/common/base-selector'
import BaseDatepicker from '~/components/common/base-datepicker.vue'
import { DATE_FORMATS } from '@/config/constant'
import { LOTTERY } from '~/resources/lottery'
import useNavigationGame from '~/composables/useNavigationGame'

const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const { $config } = useNuxtApp()
const maxDate = ref(dayjs())
const handleClick = (lottery) => {
  const gameItem = {
    type: 'game',
    partner_game_id: lottery.partnerGameId,
    partner_provider: lottery.partnerProvider
  }
  navigationCheckLoggedInAndOpenGame(gameItem)
}
const {
  handleSelectDateOpen,
  selecteTypeRef,
  selecteProvinceRef,
  lotteryResult,
  listCity,
  lotteryType,
  listLotteryType,
  title,
  lotoTitle,
  dau,
  duoi,
  date,
  cityId,
  LOTTERY_TYPE,
  listLottery,
  prizeList,
  openDatePicker
} = useLottery()

</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/lottery/index.scss"></style>
