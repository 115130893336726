<template>
  <div class="hero-banner">
    <Swiper v-if="bannerData && bannerData.length" ref="banner" v-bind="swiperOption" class="mySwiper">
      <SwiperSlide v-for="(item, idx) in bannerData" :key="item.id" class="swiper-slide">
        <BannerItem :item="item" :index="idx" @click="handleClickBanner(item)" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, EffectCoverflow, Navigation } from 'swiper'
import BannerItem from '~/components/desktop/pages/home/banner/banner-item.vue'
import useHeroBanner from '~~/composables/home/useHeroBanner'
const { bannerData, fetchBanner, handleClickBanner } = useHeroBanner()
const swiperOption = reactive({
  grabCursor: true,
  initialSlide: 0,
  observer: true,
  loop: true,
  slidesPerView: 1,
  slidesPerGroup: 1,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  centeredSlides: true,
  spaceBetween: 0,
  pagination: {
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  modules: [Autoplay, Pagination, EffectCoverflow, Navigation]
})

onMounted(() => {
  fetchBanner()
})
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/hero-banner/index.scss"></style>
