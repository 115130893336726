export const LOTTERY = [
  {
    link: '#!',
    image: '/assets/images/components/desktop/home/lottery/thumb-3-mien.png',
    name: 'Xổ số',
    type: ' 3 MIỀN',
    partnerProvider: 'vingame',
    partnerGameId: 'lottery'
  },
  {
    link: '#!',
    image: '/assets/images/components/desktop/home/lottery/thumb-1-phut.png',
    name: 'Xổ số',
    type: '1 PHÚT',
    partnerProvider: 'vingame',
    partnerGameId: 'lode_virtual'
  },
  {
    link: '#!',
    image: '/assets/images/components/desktop/home/lottery/thumb-xs-vietlott.png',
    name: 'Xổ số',
    type: 'Vietlott 655',
    partnerProvider: 'vingame',
    partnerGameId: 'vietlott_power655'
  },
  {
    link: '#!',
    image: '/assets/images/components/desktop/home/lottery/thumb-keno-vietlott.png',
    name: 'Keno',
    type: 'Vietlott',
    partnerProvider: 'keno-vietlot',
    partnerGameId: 'keno-vietlot'
  }
]
export const LOBBY_LOTTERY = [
  {
    link: '#!',
    image: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-ba-mien.webp',
    imageMobile: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-ba-mien.webp',
    title: 'Xổ số 3 Miền'
  },
  {
    link: '#!',
    image: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-1p.webp',
    imageMobile: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-1p.webp',
    title: 'Xổ số 1 phút'
  },
  {
    link: '#!',
    image: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-3p.webp',
    imageMobile: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-3p.webp',
    title: 'Xổ số 3 phút'
  },
  {
    link: '#!',
    image: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-5p.webp',
    imageMobile: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-5p.webp',
    title: 'Xổ số 5 phút'
  }
]
