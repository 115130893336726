<template>
  <div class="section-contact">
    <div class="section-contact__header">
      <p class="title">Liên hệ</p>
    </div>
    <div class="section-contact__list">
      <div v-for="item in CONTACTS" :key="item.name">
        <div v-if="item.isLiveChat" class="support__item--link" @click="openLiveChat()">
          <ContactItem :item="item" />
        </div>
        <NuxtLink v-else :to="item.url || '#'" target="_blank" class="support__item--link">
          <ContactItem :item="item" />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ContactItem from './contact-item.vue'
import { useContact } from '~~/composables/useContact'
import { useFooterNavigation } from '~/composables/useFooter'
import { useContactStore } from '~/store/contact'

const { supportMenus } = useFooterNavigation()
const { $pinia } = useNuxtApp()

const CONTACTS = supportMenus

const { openLiveChat } = useContact()
const { fetchContact } = useContactStore($pinia)

useAsyncData(async () => await fetchContact())

</script>

<style lang="scss" scoped src="/assets/scss/components/mobile/pages/home/contact/index.scss"></style>
