import { PAGE_URLS } from '~~/config/page-url'

export type TInfo = {
  name: string
  title: string
  icon: string
  link: string
}

const PATH_ICON = '/assets/images/icon/'

export const INFO: TInfo[] = [
  {
    name: 'tintuc',
    title: 'Tin Tức',
    icon: PATH_ICON + 'tin-tuc.svg',
    link: PAGE_URLS.NEWS
  },
  {
    name: 'gioithieu',
    title: 'Giới Thiệu',
    icon: PATH_ICON + 'tiara.svg',
    link: PAGE_URLS.INTRODUCTION
  },
  {
    name: 'dieukhoan',
    title: 'Điều Khoản',
    icon: PATH_ICON + 'security-user.svg',
    link: PAGE_URLS.POLICY
  },
  {
    name: 'baomat',
    title: 'Bảo Mật',
    icon: PATH_ICON + 'lock.svg',
    link: PAGE_URLS.SECURITY
  }
]
