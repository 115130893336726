import { SPORTS_URLS } from '~~/config/page-url'

export const SPORTS = [
  {
    link: SPORTS_URLS.K_SPORT,
    image: '/assets/images/components/desktop/home/sport/k-sports.png',
    name: 'K Sports'
  },
  {
    link: SPORTS_URLS.C_SPORT,
    image: '/assets/images/components/desktop/home/sport/c-sports.png',
    name: 'C Sports'
  },
  {
    link: SPORTS_URLS.P_SPORT,
    image: '/assets/images/components/desktop/home/sport/bti-sports.png',
    name: 'BTI Sports'
  }
]

export const SPORTS_MOBILE = [
  {
    title: 'K-SPORTS',
    link: '/ksports',
    label: 'K',
    image: '/assets/images/components/mobile/pages/lobby-sports/k-sport.webp',
    loginRequired: false,
    newTab: false
  },
  {
    title: 'C-SPORTS',
    link: '/csports',
    label: 'C',
    image: '/assets/images/components/mobile/pages/lobby-sports/c-sport.webp',
    loginRequired: false,
    newTab: false
  },
  {
    title: 'BTI-SPORTS',
    link: '/psports',
    label: 'P',
    image: '/assets/images/components/mobile/pages/lobby-sports/p-sport.webp',
    loginRequired: false,
    newTab: false
  },
  {
    title: 'Thể Thao Ảo',
    link: '/vssport',
    label: '',
    image: '/assets/images/components/mobile/pages/lobby-sports/the-thao-ao.webp',
    loginRequired: true,
    newTab: false
  },
  {
    title: 'E-Sports',
    link: '/esports',
    label: '',
    image: '/assets/images/components/mobile/pages/lobby-sports/e-sport.webp',
    loginRequired: true,
    newTab: false
  },
  {
    title: 'Lobby-Sports',
    link: '/lobby-sports/schedules',
    label: 'lobby',
    image: '/assets/images/components/mobile/pages/lobby-sports/lich-thi-dau.webp',
    loginRequired: true,
    newTab: false
  }
]
