<template>
  <div class="home">
    <div class="home-top">
      <Banner />
    </div>
    <GameCenter />
    <section class="section-list">
      <Sport />
      <Lottery />
      <LiveCasino />
    </section>
    <HomePromotion />
  </div>
</template>

<script setup lang="ts">
import Sport from './sport/index.vue'
import Lottery from './lottery/index.vue'
import LiveCasino from './live-casino/index.vue'
import HomePromotion from './promotion/index.vue'
import Banner from '~/components/desktop/pages/home/banner/index.vue'
import GameCenter from '~/components/desktop/pages/home/game-center/index.vue'

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
})
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/index.scss" />
