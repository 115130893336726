import { PAGE } from '~/constants/router'
import { LOBBY_GAME_URLS } from '~/config/page-url'

export const HOME_LIVE_CASINO = [
  {
    key: 'rikvip',
    title: 'Rikvip',
    link: '/livecasino?partner=rik',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'vivo-sexy',
    partner_game_id: 'baccarat',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-rikvip.png',
    type: 'casino'
  },
  {
    key: 'go88',
    title: 'Go88',
    link: '/livecasino?partner=go',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'allbet',
    partner_game_id: '',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-go88.png',
    type: 'casino'
  },
  {
    key: 'evolution',
    title: 'Evolution',
    link: '/livecasino?partner=evo',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'evo',
    partner_game_id: 'baccarat',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-evo.png',
    type: 'casino'
  },
  {
    key: 'vivo',
    title: 'Vivo Gaming',
    link: '/livecasino?partner=vivo',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'vivo',
    partner_game_id: 'baccarat',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-vivo-gaming.png',
    type: 'casino'
  },
  {
    key: 'pragmatic',
    title: 'Pragmatic',
    link: '/livecasino?partner=pragmatic',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'pragmatic',
    partner_game_id: 'all',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-pragmatic.png',
    type: 'casino'
  },
  {
    key: 'mg',
    title: 'MG Live',
    link: '/livecasino?partner=mg',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'mg',
    partner_game_id: 'MPBaccarat',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-mg-live.png',
    type: 'casino'
  },
  {
    key: 'ezugi',
    title: 'Ezugi',
    link: '/livecasino?partner=ezugi',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'ezugi',
    partner_game_id: 'sicbo',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-ezugi.png',
    type: 'casino'
  },
  {
    key: 'ebet',
    title: 'Ebet',
    link: '/livecasino?partner=ebet',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'ebet',
    partner_game_id: '4',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-ebet.png',
    type: 'casino'
  }
]

export const HOME_GAMES_MB = [
  {
    id: 'sport',
    title: 'Thể Thao',
    image: '/assets/images/components/mobile/pages/home/section-games/img-sport.png',
    link: PAGE.SPORTS
  },
  {
    id: 'table-games',
    title: 'Table Games',
    image: '/assets/images/components/mobile/pages/home/section-games/img-table-games.png',
    link: LOBBY_GAME_URLS.TABLE_GAME,
    jackpot: 'jackpotIngame'
  },
  {
    id: 'quay-so',
    title: 'Quay Số',
    image: '/assets/images/components/mobile/pages/home/section-games/img-quay-so.png',
    link: LOBBY_GAME_URLS.QUAY_SO
  },
  {
    id: 'lottery',
    title: 'Lô Đề',
    image: '/assets/images/components/mobile/pages/home/section-games/img-lottery.png',
    link: LOBBY_GAME_URLS.LO_DE
  },
  {
    id: 'nohu',
    title: 'Nổ Hũ',
    image: '/assets/images/components/mobile/pages/home/section-games/img-nohu.png',
    link: LOBBY_GAME_URLS.NO_HU,
    jackpot: 'jackpotNohu'
  },
  {
    id: 'game-bai',
    title: 'Game Bài',
    image: '/assets/images/components/mobile/pages/home/section-games/img-game-bai.png',
    link: LOBBY_GAME_URLS.GAME_BAI
  },
  {
    id: 'ban-ca',
    title: 'Bắn Cá',
    image: '/assets/images/components/mobile/pages/home/section-games/img-ban-ca.png',
    link: LOBBY_GAME_URLS.BAN_CA,
    jackpot: 'jackpotFishing'
  },
  {
    id: 'slots',
    title: 'Slots',
    image: '/assets/images/components/mobile/pages/home/section-games/img-slots.png',
    link: LOBBY_GAME_URLS.SLOTS_GAME
  },
  {
    id: 'game-nhanh',
    title: 'Game Nhanh',
    image: '/assets/images/components/mobile/pages/home/section-games/img-game-nhanh.png',
    link: LOBBY_GAME_URLS.QUICK_GAMES
  }
]

export const HOME_LOTTERY_MB = [
  {
    link: '#!',
    image: '/assets/images/components/mobile/pages/home/lottery/img-3-mien.png',
    title: 'Xổ Số 3 Miền',
    type: '3-mien',
    description: 'Lần quay số tiếp theo bắt đầu trong',
    showCountDown: true,
    partnerGameId: 'lottery',
    partnerProvider: 'vingame'
  },
  {
    link: '#!',
    image: '/assets/images/components/mobile/pages/home/lottery/img-1-phut.png',
    title: 'Xổ Số 1 Phút',
    type: '1-phut',
    description: 'Lần quay số tiếp theo bắt đầu trong',
    showCountDown: true,
    partnerGameId: 'lode_virtual',
    partnerProvider: 'vingame'
  }
]
